import Link from 'next/link'
import { useRouter } from 'next/router'

import Icon from 'components/Icon'

import SidebarCatalogItemPanel from '../SidebarCatalogItemPanel'
import { GetMarketplaceSeller_marketplaceSeller_marketplaceCatalogs_nodes } from '../../pages/SellerPage/graphql/__generated__/GetMarketplaceSeller'

import styles from './SellerPageCatalogNavigation.module.css'

type Props = {
  sellerName: string
  sellerSlug: string
  catalogs: GetMarketplaceSeller_marketplaceSeller_marketplaceCatalogs_nodes[]
  isPreview?: boolean
}

const SellerPageFilters = ({ sellerName, sellerSlug, catalogs, isPreview }: Props) => {
  const router = useRouter()
  const catalog = catalogs.find(catalog => catalog.slug === router.query.catalog)

  return (
    <div className={styles.filtersContainer}>
      {catalogs.length > 1 && (
        <p className={styles.filterHeading}>
          {router.query.catalog ? (
            <Link href={`/${router.query.seller}${isPreview ? '?preview=1' : ''}`}>
              <a className={styles.allCatalogsLink}>
                <Icon kind="arrow-left" size={16} className={styles.icon} />
                <span>More {sellerName} Catalogs</span>
              </a>
            </Link>
          ) : (
            <>{sellerName} Catalogs</>
          )}
        </p>
      )}
      <div>
        {catalog ? (
          // Render single catalog in CatalogPage
          <SidebarCatalogItemPanel sellerSlug={sellerSlug} catalog={catalog} soloCatalogMode />
        ) : (
          // Render all catalogs in SellerPage
          <>
            {catalogs.map((catalog, i) => (
              <SidebarCatalogItemPanel
                key={i}
                sellerSlug={sellerSlug}
                catalog={catalog}
                soloCatalogMode={catalogs.length === 1}
              />
            ))}
          </>
        )}
      </div>
      <div className={styles.divider} />
    </div>
  )
}

export default SellerPageFilters
