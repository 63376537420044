import { useRouter } from 'next/router'
import isEqual from 'lodash.isequal'
import { arrayParam, QueryStringParams } from 'lib/query-helper'
import CheckboxRadio from 'components/CheckboxRadio'

import ProductCategoriesFilter from 'modules/marketplace/search/components/ProductCategoriesFilter'

import { TopProductCategoriesFragment } from 'modules/marketplace/common/components/SellerProfile/graphql/__generated__/TopProductCategoriesFragment'
import {
  GetMarketplaceSeller_marketplaceSeller_marketplaceCatalogs_nodes_customTags,
  GetMarketplaceSeller_marketplaceSeller_topBrandValues
} from '../../pages/SellerPage/graphql/__generated__/GetMarketplaceSeller'
import { ProductBadgeEnum } from '../../../../../../__generated__/globalTypes'

import styles from './SellerPageFilters.module.css'

interface FilterGroup {
  heading: string
  name: string
  isMultiSelect?: boolean
  options: Record<string, unknown>[]
}

type Props = {
  topBrandValues?: GetMarketplaceSeller_marketplaceSeller_topBrandValues[]
  topProductCategories?: TopProductCategoriesFragment[]
  customTags?: GetMarketplaceSeller_marketplaceSeller_marketplaceCatalogs_nodes_customTags[]
  productContainerOffset?: number
}

const SellerPageFilters = ({ topBrandValues, topProductCategories, customTags, productContainerOffset }: Props) => {
  const router = useRouter()

  const filters = {
    brandValueIds: arrayParam(router.query.brandValueIds) || ['all'],
    badges: arrayParam(router.query.badges) || ['all'],
    customTags: arrayParam(router.query.customTags) || ['all']
  }

  const customTagsValues = customTags
    ? customTags?.map(tag => {
        return { label: tag.name, value: tag.name }
      })
    : []

  const topBrandValuesOptions = topBrandValues
    ? topBrandValues.map(bv => {
        return { label: bv.brandValue.name, value: bv.brandValue.id }
      })
    : []

  const LABEL_FILTER_GROUPS = [
    {
      heading: 'Labels',
      name: 'badges',
      isMultiSelect: true,
      options: [
        {
          label: 'Show All',
          value: 'all'
        },
        {
          label: 'New',
          value: 'NEW'
        },
        {
          label: 'Best Seller',
          value: 'BEST_SELLER'
        },
        {
          label: 'Special',
          value: 'SPECIAL'
        },
        {
          label: 'Upcoming',
          value: 'UPCOMING'
        },
        {
          label: 'Sold Out',
          value: 'SOLD_OUT'
        },
        {
          label: 'Discontinued',
          value: 'DISCONTINUED'
        }
      ]
    }
  ]

  const FILTER_GROUPS = [
    {
      heading: 'Tags',
      name: 'customTags',
      isMultiSelect: true,
      options: [
        {
          label: 'Show All',
          value: 'all'
        },
        ...customTagsValues
      ]
    },
    {
      heading: 'Brand Values',
      name: 'brandValueIds',
      isMultiSelect: true,
      options: [
        {
          label: 'Show All',
          value: 'all'
        },
        ...topBrandValuesOptions
      ]
    }
  ]

  const handleFilterChange = (filterGroup: FilterGroup, id: string) => {
    let params
    // If radio button with only a single option selectable
    if (!filterGroup.isMultiSelect) {
      params = {
        ...filters,
        [filterGroup.name]: [id]
      }
      // If checkboxes with multiple options selectable
    } else {
      const filterGroupValues = filters[filterGroup.name as keyof typeof filters]

      // Reset to all
      if (id === 'all' || (filterGroupValues.includes(id) && filterGroupValues.length === 1)) {
        params = {
          ...filters,
          [filterGroup.name]: ['all']
        }
        // Option is one of many currently checked, unchecked it
      } else if (filterGroupValues.includes(id)) {
        params = {
          ...filters,
          [filterGroup.name]: filterGroupValues.filter(
            selectedOption => selectedOption != id && selectedOption != 'all'
          )
        }
        // Default - add option to existing selection
      } else {
        params = {
          ...filters,
          [filterGroup.name]: [...filterGroupValues.filter(selectedOption => selectedOption != 'all'), id]
        }
      }
    }

    const query: QueryStringParams = {
      ...router.query,
      badges:
        params.badges.includes('all') || isEqual(params.badges.sort(), Object.values(ProductBadgeEnum).sort())
          ? null
          : params.badges.join(','),
      customTags:
        params.customTags.includes('all') ||
        isEqual(params.customTags.sort(), customTagsValues.map(tag => tag.value).sort())
          ? null
          : params.customTags.join(','),
      brandValueIds:
        params.brandValueIds.includes('all') ||
        isEqual(params.brandValueIds.sort(), topBrandValuesOptions.map(bv => bv.value).sort())
          ? null
          : params.brandValueIds.join(',')
    }

    // Remove empty filters from query
    for (const [key, value] of Object.entries(query)) {
      if (Array.isArray(value)) {
        query[key] = value.join(',')
      }
      if (!query[key]) {
        delete query[key]
      }
    }
    // Remove page / pagination
    // If we're refiltering catalogs, we want to go back ot the start of the new results
    if (query.page) {
      delete query.page
    }

    router
      .push(
        {
          pathname: router.pathname,
          query
        },
        undefined,
        { shallow: true } /* Shallow avoids scrolling to the top of the page when filtering products */
      )
      .then(() => {
        if (productContainerOffset) {
          window.scrollTo({
            top: productContainerOffset,
            behavior: 'smooth'
          })
        }
      })
  }

  return (
    <div className={styles.filtersContainer}>
      {LABEL_FILTER_GROUPS.map(filterGroup => (
        <div key={filterGroup.name}>
          {filterGroup.options.length > 1 && (
            <div key={filterGroup.name}>
              <p className={styles.filterHeading}>{filterGroup.heading}</p>
              {filterGroup.options.map((option, index) => (
                <CheckboxRadio
                  key={index}
                  name={filterGroup.name}
                  type={filterGroup.isMultiSelect ? 'checkbox' : 'radio'}
                  onChange={() => handleFilterChange(filterGroup, option.value as string)}
                  label={option.label as string}
                  checked={filters[filterGroup.name as keyof typeof filters].includes(option.value as string)}
                  className={styles.field}
                />
              ))}
              <div className={styles.divider} />
            </div>
          )}
        </div>
      ))}

      {(topProductCategories?.length ?? 0 > 0) && <ProductCategoriesFilter />}

      {FILTER_GROUPS.map(filterGroup => (
        <div key={filterGroup.name}>
          {filterGroup.options.length > 1 && (
            <div key={filterGroup.name}>
              <p className={styles.filterHeading}>{filterGroup.heading}</p>
              {filterGroup.options.map((option, index) => (
                <CheckboxRadio
                  key={index}
                  name={filterGroup.name}
                  type={filterGroup.isMultiSelect ? 'checkbox' : 'radio'}
                  onChange={() => handleFilterChange(filterGroup, option.value as string)}
                  label={option.label as string}
                  checked={filters[filterGroup.name as keyof typeof filters].includes(option.value as string)}
                  className={styles.field}
                />
              ))}
              <div className={styles.divider} />
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

const useResetProductFilters = () => {
  const router = useRouter()

  return () => {
    const query = router.query
    if (query.badges) {
      delete query.badges
    }
    if (query.customTags) {
      delete query.customTags
    }
    if (query.brandValueIds) {
      delete query.brandValueIds
    }
    router.push(
      {
        pathname: router.pathname,
        query
      },
      undefined,
      { shallow: true }
    )
  }
}

export { useResetProductFilters }
export default SellerPageFilters
