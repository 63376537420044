import React, { useState, useEffect, useRef, useCallback } from 'react'
import { GetServerSidePropsContext } from 'next'
import { useRouter } from 'next/router'
import Head from 'next/head'
import { useLazyQuery, useQuery } from '@apollo/client'
import { arrayParam, stringParam } from 'lib/query-helper'
import { ParsedUrlQuery } from 'querystring'
import uniqWith from 'lodash.uniqwith'

import { useErrorContext } from 'components/ErrorContext'
import { useUserSession, getSessionAccountId } from 'hooks/useUserSession'
import runMiddleware from 'lib/middleware'
import { initializeApollo, addApolloState } from 'lib/apolloClient'

/* GraphQL */
import GET_SUPER_DEPARTMENTS from 'modules/marketplace/header/components/DepartmentsMenuBar/graphql/GetSuperDepartments.graphql'

import GET_MARKETPLACE_SELLER_PRODUCTS from 'modules/marketplace/catalog/pages/SellerPage/graphql/GetMarketplaceSellerProducts.graphql'
import {
  GetMarketplaceSellerProducts,
  GetMarketplaceSellerProductsVariables
} from 'modules/marketplace/catalog/pages/SellerPage/graphql/__generated__/GetMarketplaceSellerProducts'

import GET_MARKETPLACE_SELLER from 'modules/marketplace/catalog/pages/SellerPage/graphql/GetMarketplaceSeller.graphql'
import {
  GetMarketplaceSeller,
  GetMarketplaceSellerVariables
} from 'modules/marketplace/catalog/pages/SellerPage/graphql/__generated__/GetMarketplaceSeller'
import {
  BuyerStatusEnum,
  PricingRequestStatusEnum,
  ProductSortingEnum,
  SortDirectionEnum
} from '../../../../../../__generated__/globalTypes'

import { ProductBadgeEnum } from '../../../../../../__generated__/globalTypes'

/* Components */
import Button from 'components/Button'
import ProgressSpinner from 'components/ProgressSpinner'
import { Robots } from 'components/SEO'
import Pagination from 'components/Pagination'

/* Marketplace Components */
import BasePage from 'modules/marketplace/layout/components/BasePage'
import { Container } from 'modules/marketplace/layout/components/ResponsiveContainers'
import EmptyState from 'modules/marketplace/common/components/EmptyState'
import GridContainer from 'modules/marketplace/layout/components/GridContainer'
import DepartmentsMenuBar from 'modules/marketplace/header/components/DepartmentsMenuBar'
import ModalSidebarContainer, {
  ModalSidebarHeader,
  ModalSidebarBody
} from 'modules/marketplace/common/components/ModalSidebarContainer'
import PricingRequestPanel from 'modules/marketplace/pricing/containers/PricingRequestPanel'
import RetailerSignupBanner from 'modules/website/components/RetailerSignupBanner'
import SellerProfile from 'modules/marketplace/common/components/SellerProfile'

/* Module Components */
import ProductItem from 'modules/marketplace/common/components/ProductItem'
import SellerPageCatalogNavigation from 'modules/marketplace/catalog/components/SellerPageCatalogNavigation'
import SellerPageFilters, { useResetProductFilters } from 'modules/marketplace/catalog/components/SellerPageFilters'
import SellerPageProductSearch from 'modules/marketplace/catalog/components/SellerPageProductSearch'
import SellerPageProductSort from 'modules/marketplace/catalog/components/SellerPageProductSort'
import InProgressOrderModal from 'modules/marketplace/common/components/InProgressOrderModal'
import ReorderBanner from 'modules/buyer-hub/ordering/components/ReorderBanner'
import InProgressOrderBanner from 'modules/marketplace/common/components/InProgressOrderBanner'
import BannedBuyerAlertAlert from 'modules/buyer-hub/account/components/BannedBuyerAlert'

/* Module Styles */
import styles from 'modules/marketplace/catalog/pages/SellerPage/SellerPage.module.css'

const PRODUCTS_PER_PAGE = 48

function getProductQueryVariables(query: ParsedUrlQuery) {
  /* Parse Sorting Query strings
   * Perhaps an inelegant solution, but avoids changes to <ButtonDropdown />
   * and allows us to obfuscate how products are actuallu being fetched.
   * Also makes for nice and compact query strings in the URL
   */
  let sortBy
  let sortDirection
  switch (query.sort) {
    case 'newest':
      sortBy = ProductSortingEnum.CREATED_AT
      sortDirection = SortDirectionEnum.DESC
      break
    case 'az':
      sortBy = ProductSortingEnum.PRODUCT_NAME
      sortDirection = SortDirectionEnum.ASC
      break
    case 'za':
      sortBy = ProductSortingEnum.PRODUCT_NAME
      sortDirection = SortDirectionEnum.DESC
      break
    default:
      // "Relevance"
      sortBy = null
      sortDirection = null
  }

  const productQueryVariables: GetMarketplaceSellerProductsVariables = {
    // Context:
    sellerSlug: stringParam(query.seller) || '',
    catalogSlug: stringParam(query.catalog),

    // Filtering
    search: stringParam(query.search),
    customTags: arrayParam(query.customTags),
    badges: arrayParam(query.badges) as ProductBadgeEnum[],
    brandValueIds: arrayParam(query.brandValueIds),
    // Only pass the 'last' category slug to the search query
    productCategoryTaxonomySlugs: query.c ? arrayParam(query.c)?.slice(-1) : undefined,

    // Sorting
    sortBy,
    sortDirection,

    // Pagination
    page: parseInt(query.page as string) || 1,
    productsPerPage: PRODUCTS_PER_PAGE,

    // Preview Mode
    isPreview: stringParam(query.preview) === '1'
  }

  // Remove empty filters from product query variables as GraphQL doesn't like sortBy or sortDirection with null or undefined as values
  for (const [k] of Object.entries(productQueryVariables)) {
    const key = k as keyof GetMarketplaceSellerProductsVariables
    if (!productQueryVariables[key]) {
      delete productQueryVariables[key]
    }
  }

  return productQueryVariables
}

const SellerPage = () => {
  const [renderError] = useErrorContext()
  const { isGuest } = useUserSession()
  const router = useRouter()
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false)
  const resetProductFilters = useResetProductFilters()

  /* This productContainerOffset that results from all this is passed to the sidebar
   * filters so we know where to scroll the page once the filtering has occured.
   */
  const contentRef = useRef(null)
  const sideBarContainerRef = useRef(null)
  const [productContainerOffset, setProductContainerOffset] = useState(0)
  useEffect(() => {
    if (contentRef.current && sideBarContainerRef.current) {
      const { offsetTop: productOffset } = sideBarContainerRef.current
      const { offsetTop: contentOffset } = contentRef.current
      setProductContainerOffset(productOffset - contentOffset)
    }
  }, [productContainerOffset, contentRef, sideBarContainerRef])

  const sellerSlug = stringParam(router.query.seller) || ''
  const catalogSlug = stringParam(router.query.catalog)
  const isPreview = stringParam(router.query.preview) === '1'

  /* Data Fetching
   */
  const { loading: sellerLoading, data: sellerData } = useQuery<GetMarketplaceSeller, GetMarketplaceSellerVariables>(
    GET_MARKETPLACE_SELLER,
    {
      variables: {
        sellerSlug,
        isPreview
      },
      skip: !router.isReady || !sellerSlug
    }
  )

  const productQueryVariables = getProductQueryVariables(router.query)
  const seller = sellerData?.marketplaceSeller
  const buyer = sellerData?.currentAccount?.buyer
  const catalogs = seller?.marketplaceCatalogs.nodes
  const currentCatalogId = catalogs?.find(catalog => catalog.slug === catalogSlug)?.id ?? ''
  const isBuyerBanned = buyer?.status === BuyerStatusEnum.BANNED

  /*
   * Page View Mode and Products List Adjustments
   */
  const isViewingSellerPage = Boolean(catalogSlug) === false

  const buyerHasAccessOnCatalogIds = useCallback(() => {
    const approvedCatalogs =
      buyer?.pricingRequests.nodes.filter(req => req.status === PricingRequestStatusEnum.APPROVED) ?? []

    // For pending and rejected pricing requests - show all the products
    if (approvedCatalogs?.length === 0) return undefined

    const allowedCatalogIds: string[] = []
    buyer?.pricingRequests.nodes.forEach(req => {
      if (req.status === PricingRequestStatusEnum.APPROVED && (req.approvedCatalogs ?? []).length > 0) {
        req.approvedCatalogs?.forEach(catalog => {
          if (catalog.hasPricingAccess) {
            allowedCatalogIds.push(catalog.id)
          }
        })
      }
    })

    // For accepted requests with revoked/unchecked catalog access - show products, but without the pricing access
    if (allowedCatalogIds.length === 0) return undefined

    if (isViewingSellerPage) {
      return allowedCatalogIds
    } else {
      if (allowedCatalogIds.includes(currentCatalogId)) {
        return [currentCatalogId]
      } else {
        // Show products, but without the pricing access
        return undefined
      }
    }
  }, [buyer?.pricingRequests.nodes, currentCatalogId, isViewingSellerPage])

  // For catalogSlug, returning 'null' shows no products
  const catalogSlugConditions = useCallback(
    (catalogSlug: string | undefined) => {
      if (isViewingSellerPage) {
        // Remove catalogSlug from query, show all the products
        return undefined
      }

      if (isGuest || !buyer) {
        return catalogSlug
      }

      // For buyer only - pending or rejected requests, show catalog products
      if (buyerHasAccessOnCatalogIds() == undefined) {
        return catalogSlug
      }

      // For buyer only - approved requests, show only allowed product catalogs
      if (buyerHasAccessOnCatalogIds()?.length === 0) {
        return null
      }
    },
    [buyer, buyerHasAccessOnCatalogIds, isGuest, isViewingSellerPage]
  )

  // For catalogIds, returning 'undefined' shows all the products, returning '[]' shows no products
  const catalogIdsConditions = () => {
    if (isGuest || !buyer) {
      return undefined
    }

    return buyerHasAccessOnCatalogIds()
  }

  const [getMarketPlaceSellerProducts, productsResult] = useLazyQuery<
    GetMarketplaceSellerProducts,
    GetMarketplaceSellerProductsVariables
  >(GET_MARKETPLACE_SELLER_PRODUCTS, {
    variables: {
      ...productQueryVariables,
      catalogSlug: catalogSlugConditions(catalogSlug),
      catalogIds: catalogIdsConditions()
    }
  })

  useEffect(() => {
    const fetchProductsData = async () => {
      if (router.isReady && sellerData?.marketplaceSeller) {
        await getMarketPlaceSellerProducts()
      }
    }

    if (!router.isReady) return

    fetchProductsData()
  }, [getMarketPlaceSellerProducts, router.isReady, sellerData?.marketplaceSeller])

  const { loading: productsLoading, data: productsData } = productsResult

  const catalog = catalogs?.find(catalog => catalog.slug === catalogSlug)
  const firstCatalog = catalogs?.[0]
  const firstWithoutPricing = catalogs?.find(catalog => !catalog.hasPricingAccess)
  const anyCatalogHasPricingAccess = catalogs?.some(catalog => catalog.hasPricingAccess)
  const pricingCatalog =
    catalog ||
    (catalogs?.length === 1 && firstCatalog) ||
    (anyCatalogHasPricingAccess && firstWithoutPricing) ||
    firstCatalog
  const publishedProducts = productsData?.marketplaceSeller?.publishedProducts
  const catalogIdsAvailableForPricing =
    !buyerHasAccessOnCatalogIds() && isViewingSellerPage
      ? catalogs?.map(catalog => catalog.id) ?? [pricingCatalog?.id ?? '']
      : [pricingCatalog?.id ?? '']

  // State helpers
  const isSearched = router.query.search
  const isFiltered = router.query.customTags || router.query.badges

  // Empty states
  const isSearchEmpty = !productsLoading && isSearched && !isFiltered && publishedProducts?.nodes.length === 0
  const isFilterEmpty = !productsLoading && isFiltered && publishedProducts?.nodes.length === 0
  const isCatalogEmpty =
    !productsLoading && !isSearched && !isFiltered && catalogSlug && publishedProducts?.nodes.length === 0
  const isSellerEmpty =
    !productsLoading && !isSearched && !isFiltered && !catalogSlug && publishedProducts?.nodes.length === 0

  // Aggregate customTags from all catalogs if more than one is present on marketplaceSeller query result
  const aggregatedCustomTags = catalogSlug
    ? catalog?.customTags
    : catalogs
        ?.map(catalog => catalog.customTags)
        .flat(1)
        .sort((a, b) =>
          a.name?.toLowerCase() && b.name?.toLowerCase() ? (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1) : 0
        )
  const customTags = uniqWith(aggregatedCustomTags, (a, b) => a.name === b.name)

  // Need to render 404 if seller or catalog not found - nessesary for logged in users, for which getServerSideProps is skipped
  useEffect(() => {
    if (
      (!sellerLoading && !sellerData?.marketplaceSeller) ||
      (!productsLoading && productsData?.marketplaceSeller?.id && catalogSlug && !catalog)
    ) {
      renderError({ message: 'Not found', statusCode: 404 })
    }
  }, [sellerLoading, productsLoading, sellerData, productsData, renderError, catalogSlug, catalog])

  return (
    <BasePage hasMarketingFooter={isGuest} hasGuestBanner={isGuest} hideIntercom isPreview={isPreview}>
      <Head>
        <title>Shop {seller?.displayName} Wholesale - Fieldfolio</title>
        <link rel="canonical" href={`${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/${seller?.slug}`} />
        {/* SEO Recommendation: 'noindex' catalog pages in preference of seller pages */}
        <Robots content={`follow, ${router.query.catalog ? 'noindex' : 'index'}`} />
      </Head>

      <DepartmentsMenuBar />

      <Container>
        <BannedBuyerAlertAlert />
      </Container>

      {sellerLoading && <ProgressSpinner className={styles.progressSpinner} />}

      {seller && (
        <div className={styles.sellerBody} ref={contentRef}>
          {seller.bannerLargeUrl && (
            <div className={styles.bannerImage}>
              <img src={seller.bannerLargeUrl} alt={`Wholesale ${seller.displayName}`} />
            </div>
          )}

          <SellerProfile seller={seller} catalog={catalog} />

          <Container>
            <GridContainer>
              <div className={styles.pricingRequestsContainer}>
                {pricingCatalog != null && !isBuyerBanned && (
                  <PricingRequestPanel
                    sellerId={seller.id}
                    catalogIds={catalogIdsAvailableForPricing}
                    isViewingSellerPage={isViewingSellerPage}
                  />
                )}
              </div>
            </GridContainer>
          </Container>

          {buyer && !isBuyerBanned && (
            <>
              {pricingCatalog?.id && <ReorderBanner sellerId={seller.id} catalogId={pricingCatalog.id} />}

              {((catalogs ?? []).length === 1 ? firstCatalog?.hasPricingAccess : catalog?.hasPricingAccess) &&
                (catalog?.id || firstCatalog?.id) && (
                  <>
                    <InProgressOrderBanner
                      classNameContainer={styles.inProgressOrderBanner}
                      classNameBanner={styles.inProgressOrderBannerContent}
                      catalogId={catalog?.id ?? firstCatalog?.id ?? ''}
                    />
                  </>
                )}
            </>
          )}

          <Container className={styles.productsContainer}>
            <GridContainer>
              <div ref={sideBarContainerRef} className={styles.sideBarContainer}>
                <SellerPageCatalogNavigation
                  sellerName={seller.displayName}
                  sellerSlug={seller.slug}
                  catalogs={seller.marketplaceCatalogs.nodes}
                  isPreview={isPreview}
                />
                <SellerPageFilters
                  topBrandValues={seller.topBrandValues}
                  customTags={customTags}
                  productContainerOffset={productContainerOffset}
                  topProductCategories={catalog?.topProductCategoryTaxonomies}
                />
              </div>

              <div className={styles.productGridContainer}>
                <div className={styles.productGridHeader}>
                  <div className={styles.productSearchSort}>
                    <div className={styles.productSearch}>
                      <SellerPageProductSearch />
                    </div>

                    <div className={styles.productModalSidebarAndSort}>
                      <div>
                        <Button className={styles.productModalSidebarToggle} onClick={() => setMobileSidebarOpen(true)}>
                          Filters
                        </Button>
                      </div>
                      <ModalSidebarContainer
                        isOpen={isMobileSidebarOpen}
                        onExit={() => setMobileSidebarOpen(false)}
                        title="Product Filters">
                        <ModalSidebarHeader>
                          <strong>Filter Products</strong>
                          <Button icon="x" kind="transparent" onClick={() => setMobileSidebarOpen(false)} />
                        </ModalSidebarHeader>
                        <ModalSidebarBody>
                          <SellerPageCatalogNavigation
                            sellerName={seller.displayName}
                            sellerSlug={seller.slug}
                            catalogs={seller.marketplaceCatalogs.nodes}
                          />
                          <SellerPageFilters customTags={customTags} />
                          <Button isBlock kind="dark" onClick={() => setMobileSidebarOpen(false)}>
                            Show Products
                          </Button>
                        </ModalSidebarBody>
                      </ModalSidebarContainer>
                      <SellerPageProductSort />
                    </div>
                  </div>
                  {(isFiltered || isSearched) && (
                    <div className={styles.filterSearchReset}>
                      {isFiltered && <a onClick={resetProductFilters}>Reset Filters</a>}
                    </div>
                  )}
                </div>

                {productsLoading && <ProgressSpinner className={styles.progressSpinner} />}
                {!productsLoading && publishedProducts?.nodes && (
                  <>
                    <div className={styles.productGrid}>
                      {publishedProducts.nodes.map(product => {
                        const catalog = catalogs?.find(catalog => catalog.id === product.catalogId)
                        if (!catalog) return null
                        return (
                          <ProductItem
                            key={product.id}
                            seller={seller}
                            catalog={catalog}
                            product={product}
                            hasSellerName={false}
                            hasCatalogName={false}
                            isPreview={isPreview}
                            isBuyerBanned={isBuyerBanned}
                          />
                        )
                      })}
                    </div>
                    <Pagination
                      baseUrl={router.asPath}
                      origin={process.env.NEXT_PUBLIC_MARKETPLACE_URL}
                      thingName="products"
                      currentPage={router.query.page ? parseInt(router.query.page as string) : 1}
                      totalPages={publishedProducts.pagesCount ?? 1}
                      perPage={PRODUCTS_PER_PAGE}
                      showPageStatus
                      showSummary={false}
                      className={styles.pagination}
                      totalItems={publishedProducts.nodesCount ?? 1}
                    />
                  </>
                )}
                {isSearchEmpty && (
                  <EmptyState icon="search" title={`We couldn't find any matches for "${router.query.search}"`} />
                )}
                {isFilterEmpty && <EmptyState icon="filter" title={`We couldn't find any matches for your filters`} />}
                {isCatalogEmpty && (
                  <EmptyState
                    title="No products in this catalog"
                    message={`${seller.displayName} has not published products in this catalog`}
                  />
                )}
                {isSellerEmpty && <EmptyState title={`${seller.displayName} has not published any products`} />}
              </div>
            </GridContainer>
          </Container>
        </div>
      )}
      {isGuest && <RetailerSignupBanner />}

      {catalog?.id && !isBuyerBanned ? <InProgressOrderModal catalogId={catalog.id} /> : null}
    </BasePage>
  )
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  runMiddleware(context)

  const sessionAccountId = getSessionAccountId()

  // Don't bother fetching any data server side if there is an existing session.
  //
  // It feels much faster to just let the client fetch data and render the page
  if (sessionAccountId) {
    return { props: {} }
  }

  const { query } = context
  const apolloClient = initializeApollo()

  /* isPreview doesn't make any sense for SSR, however we need to provide the queries
   * with exactly the same variables so that SRR actually works
   */
  const isPreview = stringParam(query.preview) === '1'

  const { data: sellerData } = await apolloClient.query({
    query: GET_MARKETPLACE_SELLER,
    variables: {
      sellerSlug: stringParam(query.seller) || '',
      isPreview
    }
  })

  /* Make sure we 404 for sellers that do not exists / are not public */
  if (query.seller && !sellerData?.marketplaceSeller) {
    return {
      notFound: true
    }
  }

  const catalogSlug = stringParam(query.catalog)
  const seller = (sellerData as GetMarketplaceSeller)?.marketplaceSeller
  const catalog = seller?.marketplaceCatalogs.nodes.find(catalog => catalog.slug === catalogSlug)

  /* Make sure we 404 for catalogs that do not exist / are not public */
  if (catalogSlug && !catalog) {
    return {
      notFound: true
    }
  }

  // Preload main departments menu
  await apolloClient.query({ query: GET_SUPER_DEPARTMENTS })

  const productQueryVariables = getProductQueryVariables(query)
  await apolloClient.query({
    query: GET_MARKETPLACE_SELLER_PRODUCTS,
    variables: {
      ...productQueryVariables
    }
  })

  /* SEO Recommendation: Redirect catalog URLs to seller page URL if seller has a single product
   *
   * Perhaps these needs more consideration, as sellers may transition to having more than one catalog,
   * and there  may be other unintended side effects (promotions, email links, etc).
   *
   * This also means we should probaly update the catalog links to seller page links everywhere else
   * if a seller has only one catalog... which could also have unintended side effects.
   *
   * Leaving this commented-out for now.
   *
   */
  // if (query.catalog && sellerQuery.data?.marketplaceSeller.marketplaceCatalogs.nodesCount === 1) {
  //   return {
  //     redirect: {
  //       permanent: false,
  //       destination: `/${query.seller}`
  //     }
  //   }
  // }

  return addApolloState(apolloClient, { props: {} })
}

export default SellerPage
