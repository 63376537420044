import { ReactNode, useState } from 'react'
import classnames from 'classnames'

import Button, { ButtonProps } from 'components/Button/Button'
import Dropdown, { DropdownProps } from 'components/Dropdown/Dropdown'
import DropdownSection from 'components/Dropdown/DropdownSection'
import DropdownItem from 'components/Dropdown/DropdownItem'

import styles from './ButtonDropdown.module.css'

type ButtonDropdownProps = ButtonProps & {
  className?: string
  dropdownClassName?: string
  children?: ReactNode
  headerTitle?: string
  options: {
    label: string | ReactNode
    value: string
    icon?: string
    iconPosition?: 'left' | 'right'
    badge?: number | string | ReactNode
  }[]
  position?: 'left' | 'right'
  onOptionSelect: (value: string) => void
  dropdownProps?: DropdownProps
}

const ButtonDropdown = ({
  className,
  dropdownClassName,
  children,
  headerTitle,
  options,
  position = 'right',
  disabled,
  isSubmitting,
  onOptionSelect,
  dropdownProps,
  ...rest
}: ButtonDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className={styles.dropdownButton}>
      <Button
        className={classnames(styles.dropdownButton, className)}
        icon={isOpen ? 'chevron-up' : 'chevron-down'}
        iconPosition="right"
        onClick={() => !disabled && !isSubmitting && setIsOpen(!isOpen)}
        active={isOpen}
        disabled={disabled}
        isSubmitting={isSubmitting}
        {...rest}>
        {children}
      </Button>

      {isOpen && (
        <Dropdown
          className={dropdownClassName}
          headerTitle={headerTitle}
          onClickOutside={() => {
            setIsOpen(false)
          }}
          position={position}
          {...dropdownProps}>
          <DropdownSection>
            {options?.map(option => (
              <DropdownItem
                key={option.value}
                label={option.label}
                icon={option.icon}
                badge={option.badge}
                onClick={() => {
                  setIsOpen(false)
                  onOptionSelect(option.value)
                }}
              />
            ))}
          </DropdownSection>
        </Dropdown>
      )}
    </div>
  )
}

export default ButtonDropdown
