import { useQuery } from '@apollo/client'

import DuplicateOrderButton from '../DuplicateOrderButton'
import GridContainer from 'modules/marketplace/layout/components/GridContainer'
import { Container } from 'modules/marketplace/layout/components/ResponsiveContainers'

import GET_OLDEST_DRAFT_AND_LAST_POSTED_ORDERS from './graphql/GetOldestDraftAndLastPostedOrders.graphql'
import {
  GetOldestDraftAndLastPostedOrders,
  GetOldestDraftAndLastPostedOrdersVariables
} from './graphql/__generated__/GetOldestDraftAndLastPostedOrders'
import { BuyerStatusEnum } from '../../../../../../__generated__/globalTypes'

import styles from './ReorderBanner.module.css'

type ReorderBannerProps = {
  sellerId: string
  catalogId: string
}

const ReorderBanner = ({ sellerId, catalogId }: ReorderBannerProps) => {
  const { loading, data } = useQuery<GetOldestDraftAndLastPostedOrders, GetOldestDraftAndLastPostedOrdersVariables>(
    GET_OLDEST_DRAFT_AND_LAST_POSTED_ORDERS,
    {
      variables: {
        sellerId,
        catalogId
      }
    }
  )

  const isBuyerBanned = data?.currentBuyer.status === BuyerStatusEnum.BANNED
  const hasDraftOrder = Boolean(data?.currentBuyer.oldestDraftOrder.nodes.length ?? 0 > 0)
  const hasPricingAccess = data?.currentBuyer.lastPostedOrder.nodes?.[0]?.catalog?.hasPricingAccess ?? false
  const orderId = data?.currentBuyer.lastPostedOrder.nodes[0]?.id ?? ''

  if (loading || !data || hasDraftOrder || !hasPricingAccess || !orderId || isBuyerBanned) {
    return null
  }

  return (
    <div className={styles.container}>
      <Container>
        <GridContainer>
          <div className={styles.bannerContainer}>
            <DuplicateOrderButton orderId={orderId} />

            <div className={styles.content}>
              <div className={styles.textContent}>
                <p className={styles.reorderButton}>Reorder now</p>
                <p className={styles.subtext}>
                  When you reorder, we&apos;ll duplicate the last order from this catalog as a starting point for you
                </p>
              </div>
            </div>
          </div>
        </GridContainer>
      </Container>
    </div>
  )
}

export default ReorderBanner
