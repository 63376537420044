import classnames from 'classnames'
import Badge from 'components/Badge'
import { PaymentTermsOfferEnum } from '../../../../../../__generated__/globalTypes'

import styles from './PaymentTermsOfferBadge.module.css'

type PaymentTermsOfferBadgeProps = {
  className?: string
  paymentTermsOffer: PaymentTermsOfferEnum | undefined | null
}

const PaymentTermsOfferBadge = ({ className, paymentTermsOffer }: PaymentTermsOfferBadgeProps) => {
  return (
    <>
      {paymentTermsOffer && Object.values(PaymentTermsOfferEnum).includes(paymentTermsOffer) && (
        <div className={classnames(styles.paymentTermsOfferContainer, className)}>
          <Badge
            className={styles.paymentTermsOfferBadge}
            style="tinted"
            leadingIcon="clock"
            leadingIconColor="var(--colorPurple8)">
            Up to
            {paymentTermsOffer === PaymentTermsOfferEnum.UP_TO_30_DAYS
              ? ' 30 '
              : paymentTermsOffer === PaymentTermsOfferEnum.UP_TO_60_DAYS
              ? ' 60 '
              : ' 90 '}
            Day Terms
          </Badge>
        </div>
      )}
    </>
  )
}

export default PaymentTermsOfferBadge
