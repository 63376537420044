import { useRouter } from 'next/router'
import { QueryStringParams } from 'lib/query-helper'
import ButtonDropdown from 'components/ButtonDropdown'
import styles from './SellerPageProductSort.module.css'

const SellerPageFilters = () => {
  const router = useRouter()

  const sortingOptions = [
    { label: 'Relevance', value: 'relevance' },
    { label: 'Newest', value: 'newest' },
    { label: 'Name: A-Z', value: 'az' },
    { label: 'Name: Z-A', value: 'za' }
  ]

  const handleSortChange = (value: string) => {
    const query: QueryStringParams = {
      ...router.query,
      sort: value === 'relevance' ? '' : value
    }

    // Remove empty filters from query
    for (const [key, value] of Object.entries(query)) {
      if (Array.isArray(value)) {
        query[key] = value.join(',')
      }
      if (!query[key]) {
        delete query[key]
      }
    }
    // Remove page / pagination
    // If we're refiltering, we want to go back ot the start of the new results
    if (query.page) {
      delete query.page
    }

    router.push(
      {
        pathname: router.pathname,
        query
      },
      undefined,
      { shallow: true } /* Avoids scrolling to the top of the page */
    )
  }

  return (
    <div className={styles.container}>
      <span className={styles.productSortLabel}>Sort by</span>
      <ButtonDropdown
        position="left"
        options={sortingOptions}
        onOptionSelect={value => handleSortChange(value)}
        icon="chevron-down"
        iconPosition="right">
        {sortingOptions.find(option => option.value === router.query.sort)?.label || 'Relevance'}
      </ButtonDropdown>
    </div>
  )
}

export default SellerPageFilters
