import classnames from 'classnames'
import Badge from 'components/Badge'

import styles from './MinimumTotalOrderBadge.module.css'

type MinimumTotalOrderBadgeProps = {
  className?: string
  currencySymbol?: string | null
  minimumTotalOrderValue: number | undefined | null | string
}

const MinimumTotalOrderBadge = ({
  className,
  minimumTotalOrderValue,
  currencySymbol = '$'
}: MinimumTotalOrderBadgeProps) => {
  if (
    (minimumTotalOrderValue && (minimumTotalOrderValue as number) < 0) ||
    minimumTotalOrderValue == null ||
    minimumTotalOrderValue === ''
  ) {
    return null
  }

  return (
    <div className={classnames(styles.container, className)}>
      {minimumTotalOrderValue == 0 ? (
        <Badge className={styles.badge} style="tinted" leadingIcon="check-circle" leadingIconColor="var(--colorGreen8)">
          No Minimum
        </Badge>
      ) : (
        <Badge className={styles.badge} style="tinted" leadingIcon="check-circle" leadingIconColor="var(--colorGreen8)">
          {minimumTotalOrderValue == 0
            ? 'No '
            : `${currencySymbol}${Math.round(parseFloat(minimumTotalOrderValue.toString())).toLocaleString('en-US')} `}
          Minimum
        </Badge>
      )}
    </div>
  )
}

export default MinimumTotalOrderBadge
