import Link from 'next/link'
import capitalize from 'lodash.capitalize'
import classnames from 'classnames'

import PaymentTermsOfferBadge from '../PaymentTermsOfferBadge'
import MinimumTotalOrderBadge from '../MinimumTotalOrderBadge'
import FreeShippingOverBadge from '../FreeShippingOverBadge'

import { GetMarketplaceSeller_marketplaceSeller_marketplaceCatalogs_nodes } from '../../pages/SellerPage/graphql/__generated__/GetMarketplaceSeller'

import styles from './SidebarCatalogItemPanel.module.css'

type SidebarCatalogItemPanelProps = {
  className?: string
  sellerSlug: string
  catalog: GetMarketplaceSeller_marketplaceSeller_marketplaceCatalogs_nodes
  soloCatalogMode?: boolean
}

const SidebarCatalogItemPanel = ({
  className,
  sellerSlug,
  catalog,
  soloCatalogMode = false
}: SidebarCatalogItemPanelProps) => {
  const shippingCountries = catalog.shippingCountries ?? []
  const formatShippingCountries = (shippingCountries: string[]) => {
    if (shippingCountries.length === 0) return null

    return (
      <p>
        Ships to{' '}
        {shippingCountries.length === 1
          ? shippingCountries[0]
          : shippingCountries?.slice(0, -1).join(', ') + ' & ' + shippingCountries?.slice(-1)}
      </p>
    )
  }

  const href = `/${sellerSlug}/${catalog.slug}`
  const catalogId = catalog.id
  const freeShippingOver = catalog.freeShippingOver ?? null
  const freeShippingIsConditional = catalog.freeShippingIsConditional || false
  const currencyCode = catalog.currencyCode
  const currencySymbol = catalog.currencySymbol
  const paymentTermsOffer = catalog.paymentTermsOffer
  const minimumTotalOrderValue = catalog.minimumTotalOrderValue ?? null

  return (
    <div className={classnames(styles.container, className)}>
      <Link href={href} passHref>
        <a>
          <div className={classnames(styles.content, { [styles.soloCatalogMode]: soloCatalogMode })}>
            <div className={styles.textContent}>
              {soloCatalogMode && (
                <img className={styles.catalogImage} src={catalog.photoMediumUrl ?? ''} alt={catalog.name ?? ''} />
              )}
              <h5 className={styles.catalogName}>{catalog.name}</h5>

              <p className={styles.departmentsLabel}>
                {catalog.departments.map(dept => capitalize(dept.name)).join(', ')}.
              </p>

              {formatShippingCountries(shippingCountries)}
            </div>

            <div className={styles.badgeContent}>
              <MinimumTotalOrderBadge
                className={styles.noMarginBottom}
                currencySymbol={currencySymbol}
                minimumTotalOrderValue={minimumTotalOrderValue}
              />
              <FreeShippingOverBadge
                className={styles.noMarginBottom}
                catalogId={catalogId}
                freeShippingOver={freeShippingOver}
                freeShippingIsConditional={freeShippingIsConditional}
                currencyCode={currencyCode}
              />
              <PaymentTermsOfferBadge className={styles.noMarginBottom} paymentTermsOffer={paymentTermsOffer} />
            </div>
          </div>
        </a>
      </Link>
    </div>
  )
}

export default SidebarCatalogItemPanel
