import React from 'react'
import Link from 'next/link'
import Icon from 'components/Icon'
import styles from './EmptyState.module.css'

const EmptyState = ({
  icon = 'alert-circle',
  title,
  message,
  link
}: {
  icon?: string
  title: string
  message?: string
  link?: {
    href: string
    label: string
  }
}) => (
  <div className={styles.container}>
    <Icon className={styles.icon} kind={icon} size={32} />
    {title && <h4 className={styles.title}>{title}</h4>}
    {message && <p className={styles.message}>{message}</p>}
    {link && (
      <Link href={link.href}>
        <a>{link.label}</a>
      </Link>
    )}
  </div>
)

export default EmptyState
