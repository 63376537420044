import { useMutation } from 'hooks/useMutation'
import { useRouter } from 'next/router'

import Button from 'components/Button'
import { ButtonProps } from 'components/Button/Button'

import DUPLICATE_ORDER_MUTATION from './graphql/DuplicateOrderMutation.graphql'
import { DuplicateOrderMutation, DuplicateOrderMutationVariables } from './graphql/__generated__/DuplicateOrderMutation'

type DuplicateOrderButtonProps = {
  className?: string
  onCompleted?: () => void
  orderId: string
} & ButtonProps

const DuplicateOrderButton = ({
  className,
  onCompleted,
  orderId,
  isSubmitting,
  onClick,
  ...rest
}: DuplicateOrderButtonProps) => {
  const router = useRouter()

  const [duplicateOrder, { loading: duplicateOrderLoading }] = useMutation<
    DuplicateOrderMutation,
    DuplicateOrderMutationVariables
  >(DUPLICATE_ORDER_MUTATION, {
    onCompleted: data => {
      if (data.duplicateOrder.success) {
        const orderId = data.duplicateOrder.order?.id ?? ''
        onCompleted && onCompleted()
        router.push(`/bh/checkout/order/${orderId}/cart`)
      }
    },
    onError: error => {
      console.error('Failed to duplicate order', error)
    }
  })

  return (
    <Button
      {...rest}
      className={className}
      kind="primary"
      icon="copy"
      isSubmitting={duplicateOrderLoading || isSubmitting}
      disabled={duplicateOrderLoading || isSubmitting}
      onClick={async event => {
        const result = await duplicateOrder({
          variables: {
            input: {
              id: orderId
            }
          },
          update: (cache, { data }) => {
            if (data?.duplicateOrder.success) {
              const buyerId = data.duplicateOrder.order?.buyerId ?? ''
              cache.evict({ id: `Buyer:${buyerId}`, fieldName: 'orders' })
              cache.gc()
            }
          }
        })
        if (result.data?.duplicateOrder.success) {
          onClick?.(event)
        }
      }}>
      Reorder
    </Button>
  )
}

export default DuplicateOrderButton
