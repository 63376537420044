import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'

import Search from 'components/Icon/Search'
import { stringParam } from 'lib/query-helper'
import formElementStyles from 'components/FormControls/FormControls.module.css'

import styles from './SellerPageProductSearch.module.css'

type SearchFromAnywhereProps = {
  isAutoFocus?: boolean
}

const SellerPageProductSearch = ({ isAutoFocus = false }: SearchFromAnywhereProps) => {
  const router = useRouter()
  const isPreview = stringParam(router.query.preview) === '1'
  const [searchQuery, setSearchQuery] = useState<string>('')

  // If a search query is present in the URL, reflect the value in the search input.
  useEffect(() => {
    if (router.isReady && router.query.search) {
      setSearchQuery(String(router.query.search))
    }
  }, [router.isReady, router.query.search])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const handleSubmit = (event: React.FormEvent) => {
    const redirectBasePath = router.query.catalog
      ? `/${router.query.seller}/${router.query.catalog}`
      : `/${router.query.seller}`

    if (searchQuery) {
      router.push(
        `${redirectBasePath}?search=${encodeURIComponent(searchQuery)}${isPreview ? '&preview=1' : ''}`,
        undefined,
        { shallow: true }
      )
    } else {
      router.push(`${redirectBasePath}${isPreview ? '?preview=1' : ''}`, undefined, { shallow: true })
    }

    event.preventDefault()
  }

  return (
    <form className={styles.form} action="" onSubmit={handleSubmit}>
      <input
        className={classNames(styles.searchInput, formElementStyles.fieldInput, styles.inputTextForMobile)}
        autoFocus={isAutoFocus}
        type="search"
        value={searchQuery}
        placeholder="Search"
        onChange={handleChange}
      />
      <input
        className={classNames(styles.searchInput, formElementStyles.fieldInput, styles.inputTextForTablet)}
        autoFocus={isAutoFocus}
        type="search"
        value={searchQuery}
        placeholder="Search by product name or SKU"
        onChange={handleChange}
      />
      <button className={styles.searchIconButton} type="submit">
        <div className={styles.searchIcon}>
          <Search />
        </div>
      </button>
    </form>
  )
}

export default SellerPageProductSearch
