import { useRouter } from 'next/router'
import Link from 'next/link'
import classNames from 'classnames/bind'

import { arrayParam } from 'lib/query-helper'
import { ParsedUrlQuery } from 'querystring'

import Icon from 'components/Icon'

import { GenerateNestedArrayArguments, useProductCategoryTaxonomyHooks } from 'hooks/useProductCategoryTaxonomyHooks'

import styles from './ProductCategoriesFilter.module.css'
const cx = classNames.bind(styles)

type ProductCategoriesFilterProps = {
  hideEmpty?: boolean
  customPreQueryPath?: string
}

const ProductCategoriesFilter = ({ hideEmpty = false, customPreQueryPath = '' }: ProductCategoriesFilterProps) => {
  const { isProductCategoryTaxonomyloading, getNestedProductCategories } = useProductCategoryTaxonomyHooks()

  const router = useRouter()
  const { query } = router

  const convertObjectToQueryString = (obj: ParsedUrlQuery): string => {
    return Object.entries(obj)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`)
      .join('&')
  }

  const categoryQuery: string[] = arrayParam(query.c) || []

  const renderNestedCategories = (
    data: GenerateNestedArrayArguments[] | undefined,
    renderFunction: (category: GenerateNestedArrayArguments) => JSX.Element,
    keyProperty: string
  ) => {
    if (!Array.isArray(data)) {
      return null
    }

    return data.map(subCategory => {
      const hasEmptyProducts = subCategory.hasProducts === false
      const hasEmptyCatalogs = subCategory.hasCatalogs === false
      const hasEmptySellers = subCategory.hasSellers === false

      if (hideEmpty && hasEmptyProducts && hasEmptyCatalogs && hasEmptySellers) {
        return null
      }

      return (
        <div className={styles.subCategoriesContainer} key={subCategory['id']}>
          {renderFunction(subCategory)}
          {subCategory.children &&
            categoryQuery.includes(subCategory.slug) &&
            renderNestedCategories(subCategory.children, renderFunction, keyProperty)}
        </div>
      )
    })
  }

  const renderSubCategoryTitle = (category: GenerateNestedArrayArguments) => (
    <Link
      href={`${customPreQueryPath}?${convertObjectToQueryString({
        ...router.query,
        c: category.slugPath as string[]
      })}`}>
      <a>
        <span
          className={cx(
            category?.slug && categoryQuery.includes(category?.slug) ? styles.selectedCategoryName : styles.categoryName
          )}>
          {category?.name}
        </span>
      </a>
    </Link>
  )

  const resetProductCategoryFilters = () => {
    delete router.query.c

    if (router.query.type && !(router.query.c || router.query.brandValueIds)) {
      delete router.query.type
    }

    router.push({
      pathname: router.pathname,
      query
    })
  }

  if (isProductCategoryTaxonomyloading) return null

  return (
    <div className={styles.categoriesContainer}>
      <>
        {categoryQuery.length === 0 ? (
          <h4 className={styles.navigationHeading}>Categories</h4>
        ) : (
          <h4 className={styles.navigationHeading} onClick={() => resetProductCategoryFilters()}>
            <span className={styles.allCategoriesLink}>
              <Icon kind="arrow-left" size={16} />
              <span>All Categories</span>
            </span>
          </h4>
        )}

        {getNestedProductCategories.map(category => {
          return (
            <>
              {categoryQuery.length === 0 || categoryQuery.includes(category.slug) ? (
                <div key={category.id}>
                  <Link
                    href={`${customPreQueryPath}?${convertObjectToQueryString({
                      ...router.query,
                      c: category.slugPath as string[]
                    })}`}>
                    <a>
                      <span
                        className={cx(
                          categoryQuery.length > 0 && categoryQuery.includes(category.slug)
                            ? styles.selectedCategoryName
                            : styles.categoryName
                        )}>
                        {category.name}
                      </span>
                    </a>
                  </Link>

                  {/* Show subcategories recursively */}
                  {categoryQuery.length > 0 &&
                    categoryQuery.includes(category.slug) &&
                    renderNestedCategories(category.children, renderSubCategoryTitle, 'id')}
                </div>
              ) : null}
            </>
          )
        })}
      </>
    </div>
  )
}

export default ProductCategoriesFilter
